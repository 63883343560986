import { BrowserRouter, Routes, Route } from 'react-router-dom';

// import Post from './components/post';
// import TimeComponent from './components/time';
import SettingsComp from './components/computersetting';
import HeaderComponent from './components/header';
import MainPage from './components/mainpage';
import Computer from './components/post';
import ComputerSettings from './components/csets';

import { useState, useEffect } from "react";

function App() {
  const passme = "/friday in general";
  const [phraase, setPassphrase] = useState(localStorage.getItem("passphrase") || "");
  const [showStuff, setVisible] = useState((localStorage.getItem("passphrase") === passme) || false);
  
  useEffect(() => {
    localStorage.setItem("passphrase", phraase);

    if (phraase === passme) {
      setVisible(true);
    }
  }, [phraase]);

  return (
    <div>
      <HeaderComponent />
      {!showStuff ?
        (
          <input
          type="text"
          value={phraase}
          onChange={(e) => setPassphrase(e.target.value)}
          placeholder="Passphrase"
          aria-label="passphrase"/>
        ) : 
        (
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/SetComputerInfo" element={<SettingsComp />} />
              <Route path="/SetComputerInfo/:computer" element={<ComputerSettings />} />
              <Route path="/:computer" element={<Computer />} />
            </Routes>
          </BrowserRouter>
        )
      }
    </div>
  );
}

export default App;