import React, { Component } from 'react';

class OnlineComputers extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = { onlinecount: 0 };

    fetch('https://egg.srgriep.workers.dev/onlinecount', 
    {
        method: 'GET'
    })
    .then(res => res.text())
    .then((data) =>
    {
      this.setState({ onlinecount: data });
    });
  }

  render()
  {
    return(
      <b> { this.state.onlinecount } </b>
    );
  }

  componentDidMount() 
  {
    this.interval = setInterval(() => 
      fetch('https://egg.srgriep.workers.dev/onlinecount', 
      {
          method: 'GET'
      })
      .then(res => res.text())
      .then((data) =>
      {
        this.setState({ onlinecount: data });
      }), 60000);
  }

  componentWillUnmount() 
  {
    clearInterval(this.interval);
  }
}

export default OnlineComputers;