import React, { Component } from 'react';

class StatusTable extends Component 
{
    constructor()
    {
        super();
        this.forceUpdateHandler = this.forceUpdateHandler.bind(this);

        this.state = {
            computers: [],
            DataisLoaded: false,
            moveChanges: false
        };
    }

    componentDidMount() 
    {
        // make sure Access-Control-Allow-Origin is set to * in the response header
        fetch('https://egg.srgriep.workers.dev/computer/server', 
        {
            method: 'GET'
        })
        .then(res => res.json())
        .then(json => 
        {
            for (var i = 0; i < json.length; i++)
            {
                json[i].PostPosition = json[i].Position;
            }

            this.setState(
            {
                DataisLoaded: true,
                computers: json,
                moveChanges: false
            });
        });
    }

    forceUpdateHandler()
    {
        this.forceUpdate();
    }

    moveChanges()
    {
        // loop through computers and update the position if it has changed
        const { computers } = this.state;

        for (var i = 0; i < computers.length; i++)
        {
            if (computers[i].Position !== computers[i].PostPosition)
            {
                computers[i].PostPosition = computers[i].Position;

                var postObject = {
                    name: computers[i].name,
                    Position: computers[i].PostPosition
                };

                computers[i].Position = computers[i].PostPosition;

                fetch('https://egg.srgriep.workers.dev/computer/' + computers[i].name + '/server/Position',
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postObject)
                }).then(res => res.json())
                .then(json =>
                {
                    console.log(json);
                });

                fetch('https://egg.srgriep.workers.dev/reorder',
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'text/plain'
                    },
                    body: true
                }).then(res => res.text()).then(json => console.log(json));
                
                // remove the apply movement changes button
                this.setState({moveChanges: false});
            }
        }
    }

    moveUp(position)
    {
        // move the computer up in the list and re-render
        const { computers } = this.state;
        // sort by position smallest to largest
        computers.sort((a, b) => a.Position - b.Position);

        // find the computer with the position
        var computer = computers.find(computer => computer.Position === position);
        // find the computer with the position above
        var computerAbove = computers.find(computer => computer.Position === position - 1);

        // swap the positions
        computer.Position = position - 1;
        computerAbove.Position = position;

        // sort by position smallest to largest
        computers.sort((a, b) => a.Position - b.Position);

        // re-render
        this.forceUpdate();

        this.setState({moveChanges: true});
    }

    moveDown(position)
    {
        // move the computer up in the list and re-render
        const { computers } = this.state;
        // sort by position smallest to largest
        computers.sort((a, b) => a.Position - b.Position);

        // find the computer with the position
        var computer = computers.find(computer => computer.Position === position);
        // find the computer with the position above
        var computerAbove = computers.find(computer => computer.Position === position + 1);

        // swap the positions
        computer.Position = position + 1;
        computerAbove.Position = position;

        // sort by position smallest to largest
        computers.sort((a, b) => a.Position - b.Position);

        // re-render
        this.forceUpdate();

        this.setState({moveChanges: true});
    }

    makeEdit(index)
    {
        // get the computer
        const { computers } = this.state;
        var computer = computers[index];

        // get table row at index
        var table = document.getElementsByTagName('table')[0];
        var row = table.getElementsByTagName('tr')[index + 1];

        // get the cells
        var cells = row.getElementsByTagName('td');

        console.log(cells);

        // set edit button to save
        var editButton = cells[3].getElementsByTagName('button')[0];

        var colorBG = editButton.innerHTML === "Edit" ? "yellow" : "white";

        // make cell 1 (name) editable
        cells[0].contentEditable = editButton.innerHTML === "Edit" ? "true" : "false";
        cells[0].style.backgroundColor = colorBG;
        cells[0].style.color = "black";

        // make cell 2 (messageID) editable
        cells[1].contentEditable = editButton.innerHTML === "Edit" ? "true" : "false";
        cells[1].style.backgroundColor = colorBG;
        cells[1].style.color = "black";

        if (editButton.innerHTML === "Save" || editButton.innerHTML === "Add New Computer")
        {
            // update the computer
            computer.name = cells[0].innerHTML;
            computer.messageID = cells[1].innerHTML;

            // update the state
            this.setState({computers: computers});

            // update the server
            var postObject = {
                name: computer.name,
                messageID: computer.messageID,
                Position: computer.PostPosition
            };

            computer.Position = computer.PostPosition;

            if (computer.new)
            {
                fetch('https://egg.srgriep.workers.dev/computer',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postObject)
                }).then(res => res.json())
                .then(json =>
                {
                    console.log(json);
                    computer.new = false;
                });
            }
            else
            {
                fetch('https://egg.srgriep.workers.dev/computer/' + computer.name + '/server',
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postObject)
                }).then(res => res.json())
                .then(json =>
                {
                    console.log(json);
                });
            }
        }

        if (editButton.innerHTML === "Edit")
        {
            if (computer.new)
                editButton.innerHTML = "Add New Computer";
            else
                editButton.innerHTML = "Save";
        }
        else
            editButton.innerHTML = "Edit";
    }

    addComputer()
    {
        const { computers } = this.state;

        var newComputer = {
            name: "New Computer",
            messageID: "",
            Position: computers.length,
            PostPosition: computers.length,
            new: true
        };

        computers.push(newComputer);
        this.setState({computers: computers});
    }

    render()
    {
        const { DataisLoaded, computers } = this.state;
        // load data from computerdata.json
        //var data = require('../computerdata.json');

        // load data from https://egg.srgriep.workers.dev/computer/server
        if (!DataisLoaded)
            return (
                <div>
                    <h1> Loading Data!!! </h1>
                </div>
            );

        computers.sort((a, b) => a.Position - b.Position);

        const tablewidth =
        {
            width: "20%",
            padding: "10px",
            fontFamily: "Arial",
            margin: 0
        };
        const tableD =
        {
            padding: "10px",
            fontFamily: "Arial",
            textAlign: "center",
            margin: 0
        };


        return (
            <table>
                <thead>
                    <tr>
                        <th style={tablewidth}>Computer Name</th>
                        <th style={tablewidth}>Message ID</th>
                        <th style={tablewidth}></th>
                        <th style={tablewidth}></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        computers.map((computer, index) => 
                        {
                            // only show arrow up if not at top, only show the arrow itself
                            var arrowUpButton = {display: "none", };
                            var arrowDownButton = {display: "none"};

                            if (computer.Position > 0)
                            {
                                arrowUpButton = {display: "inline"};
                            }

                            if (computer.Position < computers.length - 1)
                            {
                                arrowDownButton = {display: "inline"};
                            }

                            var moveChangesStyle = {
                                display: "none"
                            };

                            if (this.state.moveChanges && computer.Position === 0)
                            {
                                moveChangesStyle = {display: "block", backgroundColor: "green", color: "white"};
                            }

                            var edDit = 
                            {
                                display: "none"
                            };

                            if (computer.new)
                            {
                                edDit = 
                                {
                                    display: "block"
                                };
                            }

                            var acomp =
                            {
                                textDecoration: "none",
                                color: "black"
                            };

                            return (
                                <tr key={index}>
                                    <td style={tableD}>
                                        {!computer.new
                                            ? <a style={acomp} href={'/SetComputerInfo/' + computer.name}>{computer.name}</a>
                                            : computer.name
                                        }
                                    </td>
                                    <td style={tableD}>{computer.messageID}</td>
                                    <td style={tableD}><button style={arrowUpButton} onClick={() => this.moveUp(computer.Position)}>⬆️</button> <button onClick={() => this.moveDown(computer.Position)} style={arrowDownButton}>⬇️</button></td>
                                    <td style={tableD}><button style={edDit} onClick={() => this.makeEdit(index)}>Edit</button></td>
                                    <td style={tableD}><button style={moveChangesStyle} onClick={() => this.moveChanges()}>Apply Movement Changes</button></td>
                                </tr>
                            );
                        })
                    }
                    <tr>
                        <td style={tableD}><button onClick={() => this.addComputer()}>Add New Computer</button></td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default StatusTable;