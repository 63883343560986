import React, { Component } from 'react';


// computer types:

var compTypes = ["Desktop", "Laptop"];

class ComputerSettingsDiv extends Component 
{
	constructor(props)
	{
		super(props);

		var comp = props.computer;

		this.state = { computer: comp }

		fetch('https://egg.srgriep.workers.dev/computer/' + comp + '/server', 
		{
			method: 'GET'
		})
		.then(res => res.json())
		.then(json => 
		{
			this.setState(
			{
				sInfo: json,
			});
		});

		fetch('https://egg.srgriep.workers.dev/version',
		{
			method: 'GET'
		})
		.then(res => res.text())
		.then(text =>
		{
			this.setState(
			{
				version: text,
			});
		});
	}

	EditDropdown(key, options)
	{
		var sI = this.state.sInfo;


		return function()
		{
			// ignore if the dropdown is already there
			if (document.getElementById("dropdown" + key)) return;

			// get the first bold element in the parent
			var bold = document.getElementById(key).getElementsByTagName("b")[0];
			// set the bold element to be hidden
			bold.style.display = "none";

			// create a dropdown
			var dropdown = document.createElement("select");
			dropdown.id = "dropdown" + key;

			// add a buffer between the dropdown and the button using a style
			dropdown.style.marginRight = "5px";
			
			// add options to the dropdown
			for (var i = 0; i < options.length; i++)
			{
				var option = document.createElement("option");
				option.value = options[i];
				option.text = options[i];
				dropdown.appendChild(option);
			}

			// set the default value to the current value
			dropdown.value = sI[key];

			// add the dropdown to the page
			var parent = document.getElementById(key);
			parent.appendChild(dropdown);

			// add a button to save the changes
			var button = document.createElement("button");
			button.innerHTML = "Save";

			// when the button is clicked, save the changes
			button.onclick = function()
			{
				var newval = document.getElementById("dropdown" + key).value;
				sI[key] = newval;
				this.setState({ sInfo: sI });

				var postjson = 
				{
					[key]: newval
				};

				fetch('https://egg.srgriep.workers.dev/computer/' + this.state.computer + '/server/' + key,
				{
					method: 'PUT',
					headers: 
					{
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(postjson)
				})

				// remove the dropdown and button
				parent.removeChild(dropdown);
				parent.removeChild(button);
				// show the bold element again
				bold.style.display = "inline";
			}.bind(this);
			parent.appendChild(button);
		}.bind(this);
	}

	Edit(key)
	{
		var sI = this.state.sInfo;

		return function()
		{
			var newval = prompt("Enter new value for " + key, sI[key]);
			if (newval !== null)
			{
				sI[key] = newval;
				this.setState({ sInfo: sI });

				var postjson = 
				{
					[key]: newval
				};

				fetch('https://egg.srgriep.workers.dev/computer/' + this.state.computer + '/server/' + key,
				{
					method: 'PUT',
					headers: 
					{
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(postjson)
				})
			}
		}.bind(this);
	}

	render()
	{
		const { sInfo } = this.state;

		if (!sInfo) return null;
		var outerDivStyle =
		{
			border: '1px solid black',
			margin: '10px',
			padding: '10px',
			backgroundColor: 'lightgreen',
		};

		var compNameStyle =
		{
			fontSize: '40px',
			padding: '10px',
		};

		var imi = "https://bikus.org/Images/" + sInfo.name + ".jpeg"

		var imgStyle =
		{
			width: '200px',
			height: '200px',
			borderRadius: '50%',
			float: 'right',
			margin: '10px',
		};

		var seconddiv =
		{
			padding: '10px',
			margin: '0px',
		};

		var margintopdiv =
		{
			margin: '0px',
		};
		margintopdiv.marginTop = '5px';

		var marginlessdiv =
		{
			margin: '0px',
		};

		var MessageIDVal = sInfo.messageID == null ? "None" : sInfo.messageID;
		var PositionVal = sInfo.Position == null ? "None" : sInfo.Position;
		var OfflineTimeVal = sInfo.OfflineTime == null ? "None" : sInfo.OfflineTime;
		var OfflineNotificationVal = sInfo.OfflineNotification == null ? "None" : sInfo.OfflineNotification;
		var OnlineTimeVal = sInfo.OnlineTime == null ? "None" : sInfo.OnlineTime;
		var ComputerTypeVal = sInfo.ComputerType == null ? "None" : sInfo.ComputerType;

		var LocalVersionVal = sInfo.LocalVersion == null ? "None" : new Date(Date.parse(sInfo.LocalVersion)).toLocaleString();
		var GlobalVersion = new Date(Date.parse(this.state.version)).toLocaleString();

		var isUpToDate = LocalVersionVal === GlobalVersion;
		var upToDateStyle = isUpToDate ? { color: 'green' } : { color: 'red' };

		return (<div>
			<div style={outerDivStyle}>
				<b style={compNameStyle}> Computer Settings for { sInfo.name } </b>
				</div>
				<img src={ imi } alt={ sInfo.name } style={imgStyle} />
				<div style={seconddiv}>
					<h2 style={marginlessdiv}>Main Settings</h2>
					<p style={margintopdiv}>
						Discord Message ID: <b onClick={this.Edit("messageID")}>{ MessageIDVal }</b>
						<br/>
						Position: <b>
						{ 
							// increment by 1 to make it human readable, and add a suffix
							(PositionVal + 1) + (PositionVal === 0 ? "st" : PositionVal === 1 ? "nd" : PositionVal === 2 ? "rd" : "th")
						}
						</b>
					</p>
				</div>
				<div style={seconddiv}>
					<h2 style={marginlessdiv}>Timing Settings</h2>
					<p style={margintopdiv}>
						Offline Time: <b onClick={this.Edit("OfflineTime")}>{ OfflineTimeVal } Minutes</b>
						<br/>
						Offline Notification Time: <b onClick={this.Edit("OfflineNotification")}>{ OfflineNotificationVal } Minutes</b>
						<br/>
						Online Notification Time: <b onClick={this.Edit("OnlineTime")}>{ OnlineTimeVal } Minutes</b>
					</p>
				</div>
				<div style={seconddiv}>
					<h2 style={marginlessdiv}>Computer Information</h2>
					<p style={margintopdiv} id="ComputerType" >
						Computer Type: <b onClick={this.EditDropdown("ComputerType", compTypes)}>{ ComputerTypeVal }</b>
					</p>
					<p style={marginlessdiv}>
						Discord Checker Version: <b style={upToDateStyle}>{ LocalVersionVal }</b>
					</p>
				</div>
			</div>
		);
	}
}

export default ComputerSettingsDiv;