import React, { Component } from 'react';

class ComputerDiv extends Component 
{
	constructor(props)
	{
		super(props);

		var comp = props.computer;

		this.state = { computer: comp }

		fetch('https://egg.srgriep.workers.dev/computer/' + comp, 
		{
			method: 'GET'
		})
		.then(res => res.json())
		.then(json => 
		{
			this.setState(
			{
				cInfo: json,
			});
		});

		fetch('https://egg.srgriep.workers.dev/computer/' + comp + '/server', 
		{
			method: 'GET'
		})
		.then(res => res.json())
		.then(json => 
		{
			this.setState(
			{
				sInfo: json,
			});
		});
	}

	render()
	{
		const { cInfo, sInfo } = this.state;

		if (!cInfo || !sInfo) return null;
		
		var outerDivStyle =
		{
			border: '1px solid black',
			margin: '10px',
			padding: '10px',
			backgroundColor: 'lightgreen',
		};

		var compNameStyle =
		{
			fontSize: '40px',
			padding: '10px',
		};

		var lastUpdStyle =
		{
			color : 'green',
		};

		// image is always on right of screen floating
		var imgStyle =
		{
			width: '200px',
			height: '200px',
			borderRadius: '50%',
		};

		var imi = "https://bikus.org/Images/" + cInfo.name + ".jpeg"
		
		var lastUpdated = new Date(cInfo.Updated);
		var offTime = sInfo.OfflineTime * 60000;
		var offNotification = sInfo.OfflineNotification * 60000;

		var now = new Date();

		var op = "Online";
		var offlih = false;

		if (cInfo.BatteryLifePercent >= 0)
		{
			if (cInfo.PowerLineStatus !== "Power")
			{
				outerDivStyle.backgroundColor = '#9acd32';
			}
		}

		if (now - lastUpdated > offTime)
		{
			outerDivStyle.backgroundColor = '#FED8B1';
			lastUpdStyle.color = 'orange';
			op = "Offline";
			imi = "https://bikus.org/Images/KatyPerry.jpeg";
			offlih = true;
		}

		if (now - lastUpdated > offNotification)
		{
			outerDivStyle.backgroundColor = '#FFCCCB';
			lastUpdStyle.color = 'red';
			op = "Offline (Notification Sent)";
		}

		var machi =
		{
			fontSize: '20px',
			padding: '10px',
		};

		var seconddiv =
		{
			padding: '10px',
			margin: '0px',
		};

		var emptydiv =
		{
			padding: '0px',
			margin: '0px',
		};

		var marginlessdiv =
		{
			margin: '0px',
		};

		var coords = cInfo.Coords;
		var lat = coords !== undefined ? coords.split(", ")[0] : "0";
		var lon = coords !== undefined ? coords.split(", ")[1] : "0";
		var googlemapUrl = "https://www.google.com/maps/?q=" + lat + "," + lon + "&hl=en&z=17&output=embed";


		var pluggedinColorStyle = { color: 'purple' };
		if (cInfo.BatteryChargeStatus !== undefined)
		{
			pluggedinColorStyle.color = cInfo.BatteryChargeStatus.includes("High") ? "green" : 
										cInfo.BatteryChargeStatus.includes("Low") ? "orange" : 
										cInfo.BatteryChargeStatus.includes("Critical") ? "red" : "black"
		}

		console.log(cInfo);

		var lastActivityUnix = cInfo.LastActivity;
		var lastActivity = new Date(lastActivityUnix * 1000);

		var gmapstyle =
		{
			border: '0',
		};

		var locateddiv =
		{
			padding: '10px',
			margin: '0px',
		};

		if (coords === "Unknown")
		{
			gmapstyle.display = 'none';
			locateddiv.display = 'none';
		}
		
		return (
			<div>
				<div style={outerDivStyle}>
					<b style={compNameStyle}> Computer Info for { cInfo.name } </b>
				</div>
				<div class="cimg">
					<div class="blur"></div>
					<img src={ imi } alt={ cInfo.name } style={imgStyle} class="imgreal"/>
				</div>
				{
					cInfo.new ?
					(
						<div style={seconddiv}>
							<b style={machi}> New Computer </b>
						</div>
					) : 
					(
						<div style={emptydiv}>
							<div style={seconddiv} class="columna">
								<b style={machi}>Machine Currently { op }</b>
								<p style={locateddiv}>
									{offlih ? "Last Seen " : (cInfo.City !== "" ? "Currently " : "Current ")}{cInfo.City !== "" ? "Located at: " : "Coords: "} <b> { cInfo.City !== "" ? cInfo.City : cInfo.Coords } </b>
									<br/>
									<br/>
									<iframe 
										src = {googlemapUrl} 
										width = "350" 
										height = "350" 
										style = {gmapstyle} 
										title="locationmap"
										allowFullScreen = ""
										loading = "async"/>
								</p>

								<p style={seconddiv}>
									{offlih ? "Last Seen" : "Currently" } <b>{ cInfo.PowerLineStatus === "Power" ? "Plugged In" : "Running on Battery" }</b>
									{
										cInfo.BatteryLifePercent >= 0 ? 
										<p style={marginlessdiv}>
											Battery Life: <b style={pluggedinColorStyle}> { Math.round(cInfo.BatteryLifePercent * 100) }% </b> <b>{cInfo.BatteryChargeStatus.includes("Charging") ? "and Charging" : ""} </b>
										</p> : null
									}
								</p>

								<p style={seconddiv}>
									Last Activity: <b>{ lastActivity.toLocaleDateString() } { lastActivity.toLocaleTimeString() }</b>
									<br/>
									Active Window: <b>{ cInfo.ActiveWindow }</b>
								</p>

								<p style={seconddiv}>
									Computer User List:
									<ul>
										{
											cInfo.Users.map((user, index) => 
											{
												var active = user.ConnectionState === "Active";
												var LoginTime = new Date(user.LoginTime);
												// if active bold the user
												return <li key={index}> 
													{ active ? <b>{ user.UserName }</b> : user.UserName } | <i> { active ? <b>{ user.ConnectionState }</b> : user.ConnectionState } </i> | { LoginTime.toLocaleDateString() } { LoginTime.toLocaleTimeString() }
												</li>
											})
										}
									</ul>
								</p>

								<p style={seconddiv}>
									Last Restart: <b>{ new Date(cInfo.LastRestart).toLocaleDateString() } { new Date(cInfo.LastRestart).toLocaleTimeString() }</b>
									<br/>
									Last Updated: <b style={lastUpdStyle}>{ lastUpdated.toLocaleDateString() } { lastUpdated.toLocaleTimeString() }</b>
								</p>
							</div>
							<div style={emptydiv} class="columnb">
								<p>
									This is where there will be a table of the computers past restart times and lengths of time between restarts.
								</p>
								<p>
									This is where there will be a table of the computers past restart times and lengths of time between restarts.
								</p>
								<p>
									This is where there will be a table of the computers past restart times and lengths of time between restarts.
								</p>
								<p>
									This is where there will be a table of the computers past restart times and lengths of time between restarts.
								</p>
								<p>
									This is where there will be a table of the computers past restart times and lengths of time between restarts.
								</p>
							</div>
						</div>
					)
				}
			</div>
		);
	}

	componentDidMount() 
	{
		this.interval = setInterval(() => {
			var comp = this.state.computer;

			fetch('https://egg.srgriep.workers.dev/computer/' + comp, 
			{
				method: 'GET'
			})
			.then(res => res.json())
			.then(json => 
			{
				this.setState(
				{
					cInfo: json,
				});
			});

			fetch('https://egg.srgriep.workers.dev/computer/' + comp + '/server',
			{
				method: 'GET'
			})
			.then(res => res.json())
			.then(json => 
			{
				this.setState(
				{
					sInfo: json,
				});
			});
		}, 60000);
	}

	componentWillUnmount() 
	{
		clearInterval(this.interval);
	}
}

export default ComputerDiv;