import React from 'react';
import { useParams } from 'react-router-dom';
import ComputerDiv from './computer';

const Computer = () => {
    const { computer } = useParams();

    // set document title
    React.useEffect(() => {
        document.title = `Computer: ${computer}`;
    }, [computer]);

    return (
        <div>
            <ComputerDiv
                computer={computer}
            />
        </div>
    );
};

export default Computer;