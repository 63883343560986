import React, { Component } from 'react';

class SimpleComputerDiv extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = { onlinecount: 0, computers: null, servers: null, sortMode: props.sortMode };

    fetch('https://egg.srgriep.workers.dev/computer', 
    {
        method: 'GET'
    })
    .then(res => res.json())
    .then(json => 
    {
      this.setState(
      {
        computers: json,
      });
    });

    fetch('https://egg.srgriep.workers.dev/computer/server', 
    {
        method: 'GET'
    })
    .then(res => res.json())
    .then(json => 
    {
      this.setState(
      {
        servers: json,
      });
    });
  }

  render()
  {
    const { computers } = this.state;

    const compNameStyle =
    {
      fontSize: '20px',
    };

    // image style is always centered and rounded
    var imgStyle =
    {
      width: '70px',
      height: '70px',
      borderRadius: '50%',
    };

    var sortMode = this.state.sortMode;
    // sort the computers by position
    if (computers)
    {
      if (sortMode === "Position")
      {
        computers.sort((a, b) =>
        {
          if (this.state.servers == null) return 0;
          var serverA = this.state.servers.find(server => server.name === a.name);
          var serverB = this.state.servers.find(server => server.name === b.name);
          if (serverA == null || serverB == null) return 0;
          if (serverA.Position < serverB.Position) return -1;
          if (serverA.Position > serverB.Position) return 1;
          return 0;
        });
      }
      // sort the computers by name
      else if (sortMode === "Name")
      {
        computers.sort((a, b) =>
        {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      }
    }

    if (!computers) return null;
    return (
      <div>
        {computers.map((computer, index) =>
          {
            var computerStyle =
            {
              textAlign: 'center',
              height: '100%',
              width: '100%',
            };

            var filldivA =
            {
              display: 'inline-block',
              width: '200px',

              padding: '10px',
              margin: '10px',

              border: '4px solid green',
              backgroundColor: 'lightgrey',
              color: 'black',
              textDecoration: 'none',
            }

            var imgi = "https://bikus.org/Images/" + computer.name + ".jpeg";
            if (this.state.servers != null)
            {
              var server = this.state.servers.find(server => server.name === computer.name);
  
              var lastUpdated = new Date(computer.Updated);
              var offTime = server.OfflineTime * 60000;
              var offNotification = server.OfflineNotification * 60000;
  
              var now = new Date();

              // border color green if the computer is online
              filldivA.borderColor = 'green';

              // if now - lastUpdated > offTime, then the computer is offline, border color orange
              if (now - lastUpdated > offTime)
              {
                filldivA.borderColor = 'orange';
                imgi = "https://bikus.org/Images/KatyPerry.jpeg";
              }

              // if now - lastUpdated > offTime + offNotification, then the computer is offline, border color red
              if (now - lastUpdated > offNotification)
              {
                filldivA.borderColor = 'red';
              }
            }

            var batteryIndicator = 
            {
              backgroundColor: 'green',
              height: '16px',
              borderRadius: '20px',
              margin: '0px',
              color: 'white',
              textAlign: 'center',
              padding: '0px',
              fontSize: '10px',
              fontWeight: 'bold',
            };

            batteryIndicator.borderColor = 'green';
            batteryIndicator.borderWidth = '2px';
            batteryIndicator.borderStyle = 'solid';

            var cBLife = computer.BatteryLifePercent;

            // round the battery life to 2 decimal places
            cBLife = Math.round(cBLife * 100) / 100;

            batteryIndicator.marginTop = '10px';
            if (cBLife >= 0)
            {
              batteryIndicator.width = (cBLife * 100) + '%';

              if (cBLife === 1)
              {
                batteryIndicator.backgroundColor = 'green';
              }
              else
              {
                if (cBLife >= .75)
                {
                  batteryIndicator.backgroundColor = '#338033';
                }
                else if (cBLife >= .6)
                {
                  batteryIndicator.backgroundColor = '#9acd32';
                }
                else if (cBLife >= .3)
                {
                  batteryIndicator.backgroundColor = '#E4D00A';
                  batteryIndicator.color = 'black';                  
                }
                else if (cBLife >= .1)
                {
                  batteryIndicator.backgroundColor = 'orange';
                  batteryIndicator.color = 'black';
                }
                else
                {
                  batteryIndicator.backgroundColor = 'red';
                }

                if (computer.BatteryChargeStatus.includes("Charging"))
                {
                  batteryIndicator.borderColor = 'yellow';
                }
                else
                {
                  batteryIndicator.borderColor = batteryIndicator.backgroundColor;
                }
              }
            }
            else
            {
              batteryIndicator.width = '100%';
            }

            batteryIndicator.width = 'calc(' + batteryIndicator.width + ' - 4px)';

            var batteryExtra =
            {
              display: 'none',
              color: 'black',
              padding: '0px',
              fontSize: '10px',
              fontWeight: 'bold',
              height: '18px',
              margin: '0px',
              textAlign: 'left',
            };

            batteryExtra.marginTop = '10px';
            batteryExtra.marginLeft = '10px';
            batteryExtra.borderColor = filldivA.backgroundColor;
            batteryExtra.borderWidth = '2px';
            batteryExtra.borderStyle = 'solid';

            var batdiv =
            {
              justifyContent: 'space-between',
              display: 'flex',
              flexDirection: 'row',
            };

            if (cBLife < .12 && cBLife >= 0)
            {
              batteryIndicator.color = 'black';
              batteryExtra.display = 'inline-block';
              batteryIndicator.flexBasis = cBLife * 100 + '%';
              batteryExtra.flexBasis = (1 - cBLife) * 100 + '%';
            }

            var cBLifeNAN = isNaN(cBLife);

            if (cBLifeNAN)
            {
              batteryIndicator.backgroundColor = 'black';
              batteryIndicator.color = 'white';
              batteryIndicator.borderColor = 'black';
            }

            return (
              <a key={index}  href={ "/" + computer.name } style={filldivA}>
                <div style={computerStyle}>
                    <img src={ imgi } alt={ computer.name } style={imgStyle} />
                    <br/>
                    <b style={compNameStyle}> { computer.name } </b>
                    <br/>
                    <br/>
                    { computer.City != null ? computer.City : null } <br/> { computer.Coords != null ? computer.Coords : null }
                    <br/>
                    <br/>
                    {
                      // computer.Updated in readable date format
                      computer.Updated != null ? new Date(computer.Updated).toLocaleString() : "Computer Not Setup"
                    }
                    <div style={batdiv}>
                      <p id={computer.name + "_HiddenBoy"} style={batteryIndicator}>{(cBLife < .12 && cBLife >= 0) ? "" : 
                            (cBLife >= 0 ? Math.round(cBLife * 100) + '%' : cBLifeNAN ? "Battery Life Not Available" : "Plugged In") }</p>
                      <p style={batteryExtra}>{Math.round(cBLife * 100) + '%'}</p>
                    </div>
                </div>
              </a>
            )
          }
        )}
      </div>
    );
  }

  componentDidMount() 
  {
    this.interval = setInterval(() => {
      fetch('https://egg.srgriep.workers.dev/computer', 
      {
          method: 'GET'
      })
      .then(res => res.json())
      .then(json => 
      {
        this.setState(
        {
          computers: json,
        });
      })

      fetch('https://egg.srgriep.workers.dev/computer/server', 
      {
          method: 'GET'
      })
      .then(res => res.json())
      .then(json => 
      {
        this.setState(
        {
          servers: json,
        });
      })
    }, 60000);
  }

  componentWillUnmount() 
  {
    clearInterval(this.interval);
  }
}

export default SimpleComputerDiv;