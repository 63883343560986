import React, { Component } from 'react';
import OnlineComputers from './online';
import SimpleComputerDiv from './simplecomputerdiv';

class MainPage extends Component 
{
    constructor(props)
    {
        super(props);
        this.state = { sortMode: "Position" };
        this.sortComputers = this.sortComputers.bind(this);
    }

    componentDidMount() 
    {
        document.title = "Computer Status"
    }

    sortComputers()
    {
        const { sortMode } = this.state;
        if (sortMode === "Position")
        {
            this.setState({ sortMode: "Name" });
            this.forceUpdate();
        }
        else
        {
            this.setState({ sortMode: "Position" });
            this.forceUpdate();
        }
    }

    render()
    {
        var sortMode = this.state.sortMode;

        const mystyle = 
        {
            color: "Green",
            padding: "10px",
            fontFamily: "Arial",
            margin: 0
        };
        const paddingandFont = 
        {
            padding: "10px",
            fontFamily: "Arial",
            margin: 0
        };

        const leftPaddingOnly = 
        {
            fontFamily: "Arial",
            margin: 0
        };
        leftPaddingOnly.paddingLeft = "10px";

        return (
            <div>
                <h2 style={mystyle}> Computer List: </h2>
                <p style={paddingandFont}>Currently Online Computers: <OnlineComputers/></p>
                <SimpleComputerDiv sortMode={sortMode} />
            </div>
        );
    }
}

export default MainPage;