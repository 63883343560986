import React, { Component } from 'react';
import StatusTable from './tablecomputers';

class SettingsComp extends Component 
{
	componentDidMount()
	{
		document.title = "Edit Computer Settings";
	}

    render()
    {
        const mystyle = 
        {
            color: "Green",
            padding: "10px",
            fontFamily: "Arial",
            margin: 0
        };
        const paddingandFont = 
        {
            padding: "10px",
            fontFamily: "Arial",
            margin: 0
        };


        return (
            <div>
                <h2 style={mystyle}> Edit Computer Settings </h2>
                <p style={paddingandFont}>Edit the offline time and notification frequency!</p>
                <p style={paddingandFont}>Also change the ordering, will result in a fresh set of posts in Discord!</p>
                
                <StatusTable />
            </div>
        );
    }
}

export default SettingsComp;