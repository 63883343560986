import React from 'react';
import { useParams } from 'react-router-dom';
import ComputerSettingsDiv from './computersettings';

const ComputerSettings = () => {
    const { computer } = useParams();

    // set document title
    React.useEffect(() => {
        document.title = `Computer Settings: ${computer}`;
    }, [computer]);

    return (
        <div>
            <ComputerSettingsDiv
                computer={computer}
            />
        </div>
    );
};

export default ComputerSettings;