import React, { Component } from 'react';

class HeaderComponent extends Component 
{
    render()
    {
        const mystyle = 
        {
            color: "white",
            backgroundColor: "Green",
            padding: "10px",
            fontFamily: "Arial",
            margin: 0
        };

        const settingsStyle =
        {
            color: "white",
            backgroundColor: "Green",
            padding: "10px",
            fontFamily: "Arial",
            margin: 0,
            float: "right",
            textDecoration: "none",
            fontSize: "1.5em"
        };

        const headingAS = 
        {
            color: "white",
            textDecoration: "none"
        };

        // add an icon on far right of h1 with a settings icon and a link to the settings page

        return (
            <div>
                <a href="/SetComputerInfo" style={settingsStyle}>⚙️</a>
                <a href='/' style={headingAS}><h1 style={mystyle}> The Data Corner </h1></a>
            </div>
        );
    }
}

export default HeaderComponent;